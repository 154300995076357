import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/router-guards/auth-guard';
import { LoginComponent } from './layout/login/login.component';
import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { RedirectComponent } from './layout/redirect/redirect.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', data: { title: 'Dashboard' }, component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'redirect', component: RedirectComponent },
  { path: 'account', loadChildren: './modules/account/account.module#AccountModule'},
  { path: 'events', data: { title: 'Events' }, loadChildren: './modules/events/events.module#EventsModule', canActivate: [AuthGuard] },
  { path: 'document-management', data: { title: 'Documents' }, loadChildren: './modules/document-management/document-management.module#DocumentManagementModule', canActivate: [AuthGuard] },
  { path: 'energy-data', data: { title: 'Energy Data' }, loadChildren: './modules/energy-data/energy-data.module#EnergyDataModule', canActivate: [AuthGuard] },
  { path: 'sustainability', data: { title: 'Sustainability' }, loadChildren: './modules/sustainability/sustainability.module#SustainabilityModule', canActivate: [AuthGuard] },
  { path: 'footfall', data: { title: 'Footfall' }, loadChildren: './modules/footfall/footfall.module#FootfallModule', canActivate: [AuthGuard] },
  { path: 'issue', data: { title: 'Helpdesk' }, loadChildren: './modules/issue/issue.module#IssueModule', canActivate: [AuthGuard] },
  { path: 'permit-request', data: { title: 'Permits' }, loadChildren: './modules/permit-request/permit-request.module#PermitRequestModule' }, 
  { path: 'property-summary', data: { title: 'Summary Reports' },  loadChildren: './modules/property-summary/property-summary-report.module#PropertySummaryModule', canActivate: [AuthGuard] },  
  { path: 'property', data: { title: 'Property Portals' }, loadChildren: './modules/property/property.module#PropertyModule', canActivate: [AuthGuard] },
  { path: 'security-management', data: { title: 'Security Management' }, loadChildren: './modules/security-management/security-management.module#SecurityManagementModule', canActivate: [AuthGuard] },
  { path: 'stacking-plan', data: { title: 'Stacking Plan' }, loadChildren: './modules/stacking-plan/stacking-plan.module#StackingPlanModule', canActivate: [AuthGuard] },
  { path: 'visitors', data: { title: 'Visitors' },  loadChildren: './modules/visitor-management-data/visitor-management-data.module#VisitorManagementDataModule' },
  { path: 'users', data: { title: 'Users' },  loadChildren: './modules/users/users.module#UsersModule', canActivate: [AuthGuard] },
  { path: 'notifications', data: { title: 'Notifications' },  loadChildren: './modules/notification-settings/notification-setting.module#NotificationSettingModule', canActivate: [AuthGuard]},
  { path: 'settings', data: { title: 'Resources' },  loadChildren: './modules/settings/settings.module#SettingsModule', canActivate: [AuthGuard] },
  { path: 'parcel', data: { title: 'Parcels' }, loadChildren: './modules/parcel-tracking/parcel-tracking.module#ParcelTrackingModule', canActivate: [AuthGuard] },
  { path: 'meters', data: { title: 'Meters' }, loadChildren: './modules/property-meters/meters.module#MetersModule', canActivate: [AuthGuard] },
  { path: 'quote', data: { title: 'Quotes' }, loadChildren: './modules/quotation/quotation.module#QuotationModule', canActivate: [AuthGuard] },
  { path: 'sms', data: { title: 'SMS' }, loadChildren: './modules/sms-messaging/sms-messaging.module#SmsMessagingModule', canActivate: [AuthGuard] },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


