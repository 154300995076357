import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UsersAPI } from '@app/shared/apis/users.api';
import { IssuesAPI } from '@app/shared/apis/issue.api';
import { ConsentAPI } from '@app/shared/apis/consent.api';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatChipList } from '@angular/material';
import { Router } from '@angular/router';
import { SharedService } from '@app/core/services/shared.service';
import swal from 'sweetalert2';
import { VisitorApi } from '@app/shared/apis/visitor.api';
import { GlobalSearchService } from '@app/core/services/global-search.service';
import { OrderByPipe } from 'ngx-pipes';
import { EpcRatingAPI } from '@app/shared/apis/epc-rating.api';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('propertyManagerInput') propertyManagerInput: ElementRef<HTMLInputElement>;
  @ViewChild('propertyInput') propertyInput: ElementRef<HTMLInputElement>;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  public filter = true;
  separatorKeysCodes: number[] = [];
  public propertyType = [
    { name: 'Industrial', value: 'Industrial' },
    { name: 'Mixed', value: 'Mixed' },
    { name: 'Office', value: 'Office' },
    { name: 'Residential', value: 'Residential' },
    { name: 'Retail', value: 'Retail' },
  ];
  public epcRatingHorizontalChart = []
  public visitorManagementChart = []
  public checkedInVisitors = []
  public expectedVisitors = [];
  public expected = 0;
  public totalAreaSQFT: number = 0;

  public propertyFilter = true;
  public propertyFilterForManager = false;

  public selectedProperties: Property[] = new Array<Property>();
  public tempSelectedPropertyId = [];
  public selectedPropertiesSameError: any;
  public propertiesToFilter: any;
  public propertyControl = new FormControl();
  public timeOut = null;
  public todayDate = new Date();
  public d = new Date(this.todayDate);
  public a = new Date(this.d.setDate(this.d.getDate()));
  public ReportedFromDate = new Date(Date.UTC(this.a.getFullYear(), this.a.getMonth(), this.a.getDate() - 30, 0, 0, 0));

  public ReportedTillDate = new Date(Date.UTC(this.a.getFullYear(), this.a.getMonth(), this.a.getDate(), 0, 0, 0))

  customColors = [
    {
      name: 'A',
      value: '#0b9444'
    },
    {
      name: 'B',
      value: '#2bb673'
    },
    {
      name: 'C',
      value: '#8ec641'
    },
    {
      name: 'D',
      value: '#f5ed30'
    },
    {
      name: 'E',
      value: '#fdaf40'
    },
    {
      name: 'F',
      value: '#f7941d'
    },
    {
      name: 'G',
      value: 'red'
    },

  ]

  issueStatusCustomColors = [
    {
      name: 'Reported',
      value: '#00A0DC'
    },
    {
      name: 'Resolved',
      value: '#8C68CB'
    },
    {
      name: 'Assigned',
      value: '#00AEB3'
    },
    {
      name: 'Work In Progress',
      value: '#F47B16'
    },
    {
      name: 'Closed',
      value: '#EC4339'
    },
    {
      name: 'Paused',
      value: '#7CB82F'
    }
  ]

  issueCategoryCustomColors = [
    {
      name: "Cleaning",
      value: '#00A0DC'
    },
    {
      name: "Fire Equipment",
      value: '#EC4339'
    },
    {
      name: "Decoration",
      value: '#8C68CB'
    },
    {
      name: "Doors",
      value: '#F47B16'
    },
    {
      name: "Lifts",
      value: '#00AEB3'
    },
    {
      name: "Lighting",
      value: '#EFB920'
    },
    {
      name: "General Maintenance",
      value: '#ED4795'
    },
    {
      name: "Hot & Cold Calls",
      value: '#7CB82F'
    },
    {
      name: "Landscaping",
      value: '#86898C'
    },
    {
      name: "Window Equipment",
      value: '#F47B16'
    },
  ];

  // sortedMonths: any[];

  public energyConsumptionLineChart = [];

  public issueStatusesChart;
  public jobCategoriesChart;

  public permitStatusesChart;
  public quoteStatusesChart;
  public issueList;
  public consentLists;
  public dashboradData;
  public energyConsumption;
  public opnedIssuesCount = 0;
  public totalrecords = 0;
  public closedIssuesCount = 0;
  public visitorsExpected = [];
  public averageServiceCharge = 0;
  public propertyManagerFilterControl = new FormControl('');
  public selectedPropertyManagerName = [];
  public alreadyAddedMessagePropertyManager = '';
  public allPropertyManagers;
  public propertyFilterControl = new FormControl();
  public selectedPropertyName = [];
  public alreadyAddedMessageProperty = '';
  selectedPropertyUid = [];
  selectedPropertyManagersUid = [];
  public selectedPropertyType = '';
  public allProperties;
  public dataLoaded = false;
  public occupiedPercentage;
  public avgEnergyConsumption;
  public weGetProperties = false;
  public propertiesToAddUser: any;
  public organizationsToAddUser: any;
  public searchOrgText: any

  public fromDate = new Date();
  public tillDate = new Date();
  public todaysDate = new Date();

  type = [3];
  visitorResponse;
  User: any;

  isIssue = false;
  isConsent = false;
  isPermitToWork = false;
  isEnergy = false;
  isDocuments = false;
  isEvents = false;
  isEPC = false;
  isUsers = false;
  isFootfall = false;
  isVisitors = false;
  isWasteRecycle = false;
  isAssignVendorToJobCategory = false;
  isAddPropertyResource = false;
  isStackingPlan = false;
  isPermissionToAccess = false;
  isParcel = false;
  public propMessage = '';

  public epcRatingList: any;
  public epcHorizontalBarChartData: any;
  public dashboardProperties: any;
  globalSearchListner: any;
  filteredOptions: Observable<string[]>;
  public filterObject = {
    filters: []
  }
  public selectedPropertyNew: any;
  public proxyProperties = []

  constructor(public userApi: UsersAPI, public issueApi: IssuesAPI, public consentApi: ConsentAPI, public router: Router, public sharedService: SharedService, public visitorApi: VisitorApi, private globalSearchService: GlobalSearchService, public route: Router, private epcRatingAPI: EpcRatingAPI,
    private searchService: GlobalSearchService,) {



    this.globalSearchListner = this.searchService.globalSearchEventListner().subscribe(
      event => {
        if (window.location.pathname === "/dashboard") {
          this.globalSearchService.showSearchEventEmitter.emit(false);

        }

      });
    // this.globalSearchService.showSearchEventEmitter.emit(false);
  }

  ngOnInit() {
    this.todaysDate.setHours(0);
    this.todaysDate.setMinutes(0);
    this.todaysDate.setSeconds(0);

    let OSName = "";
    if (navigator.appVersion.indexOf("Mac") != -1) {
      OSName = "MacOS";
    };

    let object = {};
    this.getDashboradData(object);

    let pageSizeFive = {
      pageSize: 5
    };
    let visitorsFilter = {
      pageSize: 10000,
      filters: []
    }

    this.getFirstFiveIssues(pageSizeFive);
    //this.getFirstFiveConsent(pageSizeFive);
    this.getVisitorsData(visitorsFilter);
    this.getUserInfo();
    //this.getAllProperty();
  }

  getUserInfo() {
    let loggedInUser = JSON.parse(localStorage.getItem("user_data"));
    if (loggedInUser !== undefined && loggedInUser !== null && loggedInUser !== "") {
      this.User = loggedInUser
      this.getMenu();
    } else {
      this.userApi.getUserInfo().subscribe(
        response => {
          this.User = response
          this.getMenu();
        }
      )
    }
  }

  getMenu() {
    for (let i = 0; i < this.User.featureList.length; i++) {
      if (this.User.featureList[i] === 'Issue') {
        this.isIssue = true;
      } else if (this.User.featureList[i] === 'Consent') {
        this.isConsent = true;
      } else if (this.User.featureList[i] === 'Documents') {
        this.isDocuments = true;
      } else if (this.User.featureList[i] === 'Energy') {
        this.isEnergy = true;
      } else if (this.User.featureList[i] === 'Events') {
        this.isEvents = true;
      } else if (this.User.featureList[i] === 'EPC') {
        this.isEPC = false;
      } else if (this.User.featureList[i] === 'Footfall') {
        this.isFootfall = true;
      } else if (this.User.featureList[i] === 'PermitToWork') {
        this.isPermitToWork = true;
      } else if (this.User.featureList[i] === 'Users') {
        this.isUsers = true;
      } else if (this.User.featureList[i] === 'Visitors') {
        this.isVisitors = true;
      } else if (this.User.featureList[i] === 'WasteRecycle') {
        this.isWasteRecycle = true;
      } else if (this.User.featureList[i] === 'AddPropertyResource') {
        this.isAddPropertyResource = true;
      } else if (this.User.featureList[i] === 'AssignVendorToJobCategory') {
        this.isAssignVendorToJobCategory = true;
      } else if (this.User.featureList[i] === 'StackingPlan') {
        this.isStackingPlan = true;
      } else if (this.User.featureList[i] === 'PermissionToWork') {
        this.isPermissionToAccess = true;
      } else if (this.User.featureList[i] === 'Parcel') {
        this.isParcel = true;
      }

    }
  }

  getDashboradData(object) {
    this.dataLoaded = false;
    this.userApi.getDashboradData(object).subscribe(
      response => {
        this.dashboradData = response;
        this.epcRatingHorizontalChart = this.dashboradData['epcRatings'];
        this.permitStatusesChart = this.dashboradData['permitStatuses'];
        this.quoteStatusesChart = this.dashboradData['quoteStatuses'];
        this.averageServiceCharge = this.dashboradData.avgServiceCharge.toFixed(2);
        this.totalAreaSQFT = this.dashboradData.totalAreaSQFT.toLocaleString('en-UK');
        this.energyConsumption = this.dashboradData['energyConsumption'];
        this.occupiedPercentage = this.dashboradData['occupiedPercentage'];
        this.dashboardProperties = this.dashboradData['properties'];
        this.avgEnergyConsumption = this.dashboradData['avgEnergyConsumption']
        this.getPermitQuotesChartData();
        // if (this.occupiedPercentage < 0) {
        //   this.occupiedPercentage = 100;
        // } else {
        //   this.occupiedPercentage = 100 - this.occupiedPercentage;
        // }

        // this.energyConsumption = this.dashboradData['energyConsumption'];
        // this.visitorsExpected = this.dashboradData['visitorsExpected'];
        this.getChartData();
        // if (this.dashboradData['energyConsumption'] !== undefined) {
        //   for (let i = 0; i < this.dashboradData.energyConsumption.length; i++) {
        //     //console.log(this.dashboradData.energyConsumption[i]);
        //   }
        // }
      });
  }

  getPermitQuotesChartData() {
    var myObject = this.permitStatusesChart;
    var keyNames = Object.keys(myObject);
    this.permitStatusesChart = [];
    keyNames.forEach(element => {
      this.permitStatusesChart.push({ name: element + '(' + myObject[element] + ')', value: myObject[element] });
    });

    var myObject = this.quoteStatusesChart;
    var keyNames = Object.keys(myObject);
    this.quoteStatusesChart = [];
    keyNames.forEach(element => {
      this.quoteStatusesChart.push({ name: element + '(' + myObject[element] + ')', value: myObject[element] });
    });
  }

  //visitor chart code
  getVisitorChartData() {
    var myObject = this.expectedVisitors;
    var keyNames = Object.keys(myObject);
    let expectedObject = {
      name: "Expected",
      series: []
    }
    keyNames.forEach(element => {
      expectedObject.series.push({ name: this.getFormatted(element), value: myObject[element] });
    });

    var myObject1 = this.checkedInVisitors;
    var keyNames1 = Object.keys(myObject1);

    let checkedInObject = {
      name: "Checked In",
      series: []
    }
    keyNames1.forEach(element => {
      checkedInObject.series.push({ name: this.getFormatted(element), value: myObject1[element] });
    });
    this.visitorManagementChart = [];
    this.visitorManagementChart.push(expectedObject);
    this.visitorManagementChart.push(checkedInObject);
  }
  // sending object to api
  getVisitorsData(visitorsFilter) {
    //console.log('', visitorsFilter);
    let epoch = this.todaysDate.getTime();
    this.fromDate = new Date(epoch - 15 * 24 * 60 * 60 * 1000);
    this.tillDate = new Date(epoch + 15 * 24 * 60 * 60 * 1000);

    visitorsFilter['fromDate'] = this.fromDate.toISOString();
    visitorsFilter['tillDate'] = this.tillDate.toISOString();
    this.getVisitors(visitorsFilter);
  }

  /**********************************************************
   function:getAllEvents()
   purpose:this fucntion will get all the events from the api side 
   *****************************************************************/
  getVisitors(visitorObject) {
    this.visitorApi.getAllVisitor(visitorObject).subscribe(response => {
      this.visitorResponse = response;
      this.expectedVisitors = this.visitorResponse['expected'];
      this.checkedInVisitors = this.visitorResponse['checkedIn'];

      var total = 0;

      for (var el in this.expectedVisitors) {
        if (this.expectedVisitors.hasOwnProperty(el)) {
          total += parseFloat(this.expectedVisitors[el]);
        }
      }
      this.expected = total;

      this.getVisitorChartData();
    }, error => {
    });
  }

  getFirstFiveIssues(issueObject) {
    let d = new Date(this.todayDate);
    let issuesReportedFrom = new Date(this.d.getFullYear(), this.d.getMonth(), this.d.getDate() - 30);

    let issuesReportedTill = new Date(this.d.getFullYear(), this.d.getMonth(), this.d.getDate(), 23, 59);

    issueObject['issuesReportedFrom'] = issuesReportedFrom;
    issueObject['issuesReportedTill'] = issuesReportedTill;

    this.issueApi.GetAllCurrentIssuelist(issueObject).subscribe(
      (response: any) => {
        this.issueList = response;
        this.totalrecords = this.issueList['totalRecords'];
        this.jobCategoriesChart = this.issueList['jobCategories']
        this.issueStatusesChart = this.issueList['issueStatuses'];
        let closeIssueCount = this.issueList['issueStatuses'].Closed;

        if (closeIssueCount !== undefined) {
          this.closedIssuesCount = this.issueList['issueStatuses'].Closed;
          this.opnedIssuesCount = this.totalrecords - this.closedIssuesCount;
        } else {
          this.opnedIssuesCount = this.totalrecords;
          this.closedIssuesCount = 0;
        }
        this.getPieChartData();
      }
    )
  }

  getFirstFiveConsent(object) {
    this.consentApi.GetAllConsents(object).subscribe(
      (response: any) => {
        this.consentLists = response.consents;
      }
    )
  }

  getPieChartData() {
    var myObject = this.issueStatusesChart;
    var keyNames = Object.keys(myObject);
    this.issueStatusesChart = [];
    keyNames.forEach(element => {
      this.issueStatusesChart.push({ name: element + '(' + myObject[element] + ')', value: myObject[element] });
    });

    var myObject = this.jobCategoriesChart;
    var keyNames = Object.keys(myObject);
    this.jobCategoriesChart = [];
    keyNames.forEach(element => {
      this.jobCategoriesChart.push({ name: element + '(' + myObject[element] + ')', value: myObject[element] });
    });
  }

  getChartData() {
    // var myObject = this.energyConsumption;
    // var keyNames = Object.keys(myObject);
    // //console.log(keyNames);
    // this.energyConsumption = [];
    // keyNames.forEach(element => {
    //   this.energyConsumption.push({ name: element, value: myObject[element] });
    // });

    var myObject = this.dashboradData.epcRatings;
    var keyNames = Object.keys(myObject);
    let data = [];
    keyNames.forEach(element => {
      data.push({ name: element, value: myObject[element] });
    });
    this.epcHorizontalBarChartData = data;
    setTimeout(() => {
      this.dataLoaded = true;
    }, 200);

    var energyObject = this.energyConsumption;

    var energyKeys = Object.keys(energyObject);
    this.energyConsumptionLineChart = [];
    let consumptionObject =
    {
      name: "Consumption",
      series: []
    }
    energyKeys.forEach(element => {
      consumptionObject.series.push({ name: this.getMonth(element), value: energyObject[element] });
    });

    let horizontalBarData = [];
    consumptionObject.series.forEach(series => {
      let newString = series.name.split(" ");
      let newObject = {
        "month": newString[0],
        "year": newString[1],
        "value": series.value
      }
      horizontalBarData.push(newObject);
    })

    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let lastSixMonth = [];
    let today = new Date();
    for (let i = 6; i > 0; i--) {
      let d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      let month = monthNames[d.getMonth()];
      let year = d.getFullYear();
      let sixMonthData = {
        'month': month,
        'year': year
      }
      lastSixMonth.push(JSON.parse(JSON.stringify(sixMonthData)));
    }

    let newChartArray = []

    lastSixMonth.forEach(mdata => {
      horizontalBarData.forEach(hrdata => {
        if (mdata.month === hrdata.month && mdata.year === parseInt(hrdata.year)) {
          let tempChartObject = {
            month: mdata.month,
            year: mdata.year,
            value: hrdata.value
          }
          newChartArray.push(tempChartObject);
        }
      });
    });

    horizontalBarData.sort((a, b) => {
      let monthArray = {
        Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
        Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
      };
      return a.year - b.year || monthArray[a.month] - monthArray[b.month];
    })

    let tempConsumptionObject = []
    newChartArray.forEach(barData => {
      let horizontalLabel = (barData.month + ' ').concat(barData.year);
      let finalObjectForEnergyConsumption = {
        name: horizontalLabel,
        value: barData.value
      }
      tempConsumptionObject.push(finalObjectForEnergyConsumption)
    });

    consumptionObject.series = tempConsumptionObject

    this.energyConsumptionLineChart.push(consumptionObject);
    // this.energyConsumptionLineChart.push(benchMarkObject);
  }
  getFormatted(val) {
    val = new Date(val);
    var dd = val.getDate();
    var mm = val.getMonth() + 1;
    var yyyy = val.getFullYear();
    var today = dd + '/' + mm + '/' + yyyy;
    return today;
  }

  getMonth(val) {
    let monthNumber = val[0] + val[1];
    let monthText = '';

    switch (monthNumber) {
      case "01":
        monthText = 'Jan ';
        break;
      case "02":
        monthText = 'Feb ';
        break;
      case "03":
        monthText = 'Mar ';
        break;
      case "04":
        monthText = 'Apr ';
        break;
      case "05":
        monthText = 'May ';
        break;
      case "06":
        monthText = 'Jun ';
        break;
      case "07":
        monthText = 'Jul ';
        break;
      case "08":
        monthText = 'Aug ';
        break;
      case "09":
        monthText = 'Sep ';
        break;
      case "10":
        monthText = 'Oct ';
        break;
      case "11":
        monthText = 'Nov ';
        break;
      case "12":
        monthText = 'Dec ';
        break;
    }

    let monthYear = monthText + val.substring(2);
    val = monthYear;
    return val.toLocaleString();
  }


  inputEventForPropertyManager() {
    let search = this.propertyManagerFilterControl.value;
    search = search.toLowerCase();
    if (search.length > 2) {
      let object = {
        pageSize: 100,
        suggestAutocompleteQuery: search,
      }
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(t => {
        this.getAllPropertyManagerSuggestions(object);
      }, 1000);
    }
  }

  selectedPropertyManager(event: MatAutocompleteSelectedEvent) {
    if (this.selectedPropertyManagerName.length > 0) {
      this.propertyFilter = false;
      for (let i = 0; i < this.selectedPropertyManagerName.length; i++) {
        if (event.option.value.uId !== this.selectedPropertyManagerName[i].uId) {
          this.alreadyAddedMessagePropertyManager = '';
          this.selectedPropertyManagerName.push(event.option.value);
          this.selectedPropertyManagersUid.push(event.option.value.uId);
          this.propertyManagerInput.nativeElement.value = '';
          this.propertyManagerFilterControl.setValue(null);
          return false;
        } else {
          this.alreadyAddedMessagePropertyManager = "Manager already selected";
          return false;
        }
      }
    } else {
      this.propertyFilter = false;
      this.selectedPropertyManagerName.push(event.option.value);
      this.selectedPropertyManagersUid.push(event.option.value.uId);
      this.propertyManagerInput.nativeElement.value = '';
      this.propertyManagerFilterControl.setValue(null);
    }

    this.getPropertiesForAddUser();

  }

  removePropertyManager(fruit): void {
    const index = this.selectedPropertyManagerName.indexOf(fruit);
    const uidIndex = this.selectedPropertyManagersUid.indexOf(fruit.uId);
    if (index >= 0) {
      this.selectedPropertyManagerName.splice(index, 1);
    }
    if (uidIndex >= 0) {
      this.selectedPropertyManagersUid.splice(uidIndex, 1);
    }

  }



  /*****************************************************************
      getAllPropertyManagerSuggestions()
      param:
      purpose : this method will get all Property-Managers
  *******************************************************************/
  public getAllPropertyManagerSuggestions(object) {
    this.userApi.getAllPropertyManagerSuggestions(object).subscribe(
      (response: any[]) => {
        //success
        this.allPropertyManagers = response;
      },
      error => {
        //error
      })
  }

  // inputEventForProperty() {
  //   let search = this.propertyFilterControl.value;
  //   search = search.toLowerCase();
  //   if (search.length > 2) {
  //     let object = {
  //       pageSize: 100,
  //       suggestAutocompleteQuery: search,
  //     }
  //     this.getAllProperty(object);


  //   }
  // }

  /*******************************************************************************
  inputEventForProperty()
  purpose : when user type the text for searching specific property the this 
           method is triggered on each keystrock
  **********************************************************************************/
  inputEventForProperty() {
    let search = this.propertyControl.value;
    search = search.toLowerCase()
    // if (search.length > 2) {
    //   this.getAllProperties(search);
    // }
    //reduced api calls for search properties by time out method
    clearTimeout(this.timeOut);
    this.timeOut = setTimeout(t => {
      this.getAllProperties(search);
    }, 400);
  }


  selectedPropertyOption(event: MatAutocompleteSelectedEvent) {
    if (this.selectedProperties.length > 0) {
      for (let i = 0; i < this.selectedProperties.length; i++) {
        if (event.option.value.uId !== this.selectedProperties[i].uId) {
          this.selectedPropertiesSameError = '';
          this.selectedProperties.push(event.option.value);
          this.tempSelectedPropertyId.push(event.option.value.uId);
          this.propertyInput.nativeElement.value = '';
          this.propertyControl.setValue(null);

          return false;

        } else {
          this.selectedPropertiesSameError = 'Property already selected';
          return false;
        }
      }
    } else {
      this.selectedProperties.push(event.option.value);
      this.tempSelectedPropertyId.push(event.option.value.uId);
      this.propertyInput.nativeElement.value = '';
      this.propertyControl.setValue(null);
    }
  }

  /*******************************************************************************
  getAllProperties()
  parameter: property name (input values)
  purpose : return propertise which matches the inpute keystroke input value length 
            must be greater than three
  **********************************************************************************/

  getAllProperties(propertyName) {
    if (propertyName.length > 3) {
      let energyPropertyCriteria = {
        pageSize: 10,
        suggestAutocompleteQuery: propertyName
      }
      this.userApi.getAllSearchProperties(energyPropertyCriteria).subscribe(
        response => {
          this.propertiesToFilter = response['properties'];
        }, error => {

        });
    }
  }

  selectedProperty(event: MatAutocompleteSelectedEvent) {
    //console.log(event);

    if (this.selectedPropertyName.length > 0) {

      for (let i = 0; i < this.selectedPropertyName.length; i++) {
        if (event.option.value.uId !== this.selectedPropertyName[i].uId) {
          this.alreadyAddedMessageProperty = '';
          this.selectedPropertyName.push(event.option.value);
          this.selectedPropertyUid.push(event.option.value.uId);
          this.propertyInput.nativeElement.value = '';
          this.propertyFilterControl.setValue(null);
          return false;
        } else {
          this.alreadyAddedMessageProperty = "Property already selected";
          return false;
        }
      }
    } else {
      this.selectedPropertyName.push(event.option.value);
      this.selectedPropertyUid.push(event.option.value.uId);
      this.propertyInput.nativeElement.value = '';
      this.propertyFilterControl.setValue(null);
    }

  }


  /*****************************************************************
      getAllPropertyManagerSuggestions()
      param:
      purpose : this method will get all Property-Managers
  *******************************************************************/
  // public getAllProperty() {
  //   let object = {
  //     pageSize: 100,
  //     // suggestAutocompleteQuery: search,
  //   }
  //   this.userApi.getAllPropertySuggestions(object).subscribe(
  //     (response: any[]) => {
  //       //success
  //       this.allProperties = response;
  //     },
  //     error => {
  //       //error
  //     })
  // }

  // private _filter(value: string): string[] {
  //   const filterValue = value.toLowerCase();

  //   return this.options.filter(option => option.toLowerCase().includes(filterValue));
  // }

  resetFilterForm() {
    this.filter = true;
    this.propertyFilter = true;
    this.selectedPropertyNew = '';
    this.selectedProperties = [];
    this.selectedPropertyName = [];
    this.selectedPropertyManagerName = [];
    this.selectedPropertyManagersUid = [];
    this.selectedPropertyType = '';
    this.proxyProperties = [];
    let object = {};
    this.getDashboradData(object);

    let pageSizeFive = {
      pageSize: 5
    };

    let visitorsPageSize = {
      pageSize: 10000
    }
    this.getVisitorsData(visitorsPageSize);
    this.getFirstFiveIssues(pageSizeFive);
    this.getFirstFiveConsent(pageSizeFive);
  }

  navigateToIssueList() {
    this.route.navigate(['/issue/list-all']);
  }
  navigateToVisitor() {
    this.route.navigate(['visitors/dashboard']);
  }

  navigateToQuoteList() {
    this.route.navigate(['/quote/list']);
  }

  navigateToPermitList() {

    // this.route.navigate(['/permit-request/list-all']);
  }

  // navigateToEpcList(epcType) {
  //   this.sharedService.epcRatingValue = epcType;
  //   this.router.navigate(['/epc-rating/list']);
  // }

  getColor(status) {
    (2)
    switch (status) {
      case 'Reported':
        return '#92D2C1';
      case 'Paused':
        return '#45BB89';
      case 'Work In Progress':
        return '#3D82AD';
      case 'Closed':
        return '#026291';
      case 'Quote Approved':
        return '#a9aed9';
      case 'Quote Requested':
        return '#32c1b6';
      case 'Quote Submitted':
        return '#0af248';
    }
  }

  removeProperty(property) {
    this.propMessage = '';
    // const index = this.propertiesToAddUser.indexOf(property);
    const uidIndex = this.proxyProperties.findIndex(i => i.uId === property.uId);
    // if (index >= 0) {
    //   this.selectedPropertyName.splice(index, 1);
    // }
    this.proxyProperties.splice(uidIndex, 1);
    //console.log(this.proxyProperties + 'filtered ', this.propertiesToAddUser);
  }

  addProperty(property) {
    this.propMessage = '';
    let addedproperty = this.proxyProperties.find(
      d => d.uId === property.uId);
    //console.log('addedproperty', addedproperty);

    if (addedproperty) {
      this.propMessage = "Property already selected..."
    } else {
      this.propMessage = '';
      this.proxyProperties.push(property);
    }
  }


  /*****************************************************************
    getPropertiesForAddUser()
    param:
    purpose : this method will get all Properties of selected Property Manager
  *******************************************************************/

  getPropertiesForAddUser() {
    this.selectedPropertyManagersUid.forEach(propertyManager => {
      this.userApi.getAllPropertiesToAddUser(propertyManager).subscribe(
        (response: []) => {
          //console.log('Properties to add User: ', response);
          this.propertiesToAddUser = [];
          this.propertiesToAddUser = response;

          this.proxyProperties = JSON.parse(JSON.stringify(this.propertiesToAddUser));

          this.filteredOptions = this.propertyFilterControl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );

          if (this.propertiesToAddUser.length === 0) {
            this.weGetProperties = true;
            //console.log(this.weGetProperties);
          } else {
            this.weGetProperties = false;
          }
        }, error => {

        });
    })

  }


  /*****************************************************************
  searchProperty()
  purpose : this method will triggered when input is changed
  *******************************************************************/
  searchProperty() {
    // const organizationForSearch = this.searchOrganizationName.toLowerCase();
    // if (organizationForSearch.length > 2) { 
    //   this.usersAPI.getAllOrganizations(organizationForSearch);
    // }
    const selectedProp = this.propertiesToAddUser.find(d => d.name === this.searchOrgText);
    //console.log('selected property:', selectedProp);

    this.selectedPropertyUid = selectedProp.uId;
    if (selectedProp !== undefined) {
      let org = this.propertiesToAddUser;
      this.filterOrganization(org);
    }
    // else {
    //   if (this.searchOrgText !== '' && this.searchOrgText.length > 2) {
    //     this.usersAPI.getAllOrganizations()
    //   }
    // }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.propertiesToAddUser.filter(property => {
      if (property.name) {
        return property.name.toLowerCase().includes(filterValue);
      } else {
        return false;
      }
    });
  }

  public filterOrganization(org) {
    let filteredOrgs = org.filter(d => d.uId !== null);
  }

  applyFilter() {
    this.dataLoaded = false;
    this.filter = false;
    this.filterObject.filters = [];
    let object = {
      filters: []
    }

    if (this.propertyFilter === true) {
      let filter = {
        fieldName: "propertyUId",
        fieldValue: this.tempSelectedPropertyId
      }
      object.filters.push(filter);
      let commonObject = {
        pageSize: 5,
        filters: object.filters
      }

      let visitorsFilter = {
        pageSize: 10000,
        filters: object.filters
      }

      //console.log('new filters', commonObject);

      this.getFirstFiveIssues(commonObject);
      this.getFirstFiveConsent(commonObject);
      this.getVisitorsData(visitorsFilter);
      this.getDashboradData(commonObject);
    } else {
      if (this.selectedPropertyManagersUid.length > 0) {
        let manager = {
          fieldName: "propertyManagerUId",
          fieldValue: this.selectedPropertyManagersUid
        }
        this.filterObject.filters.push(manager)
      }

      if (this.proxyProperties.length > 0) {
        let propertyObj = {
          fieldName: "propertyUId",
          fieldValue: []
        }
        this.proxyProperties.forEach(property => {
          propertyObj.fieldValue.push(property.uId)
        });
        this.filterObject.filters.push(propertyObj);
      }

      this.getDashboradData(this.filterObject);

      let commonObject = {
        pageSize: 5,
        filters: this.filterObject.filters
      }
      //console.log('new filters', this.filterObject);

      this.getFirstFiveIssues(commonObject);
      this.getFirstFiveConsent(commonObject);

      let visitorsFilter = {
        pageSize: 10000,
        filters: this.filterObject.filters
      }

      this.getVisitorsData(visitorsFilter);
    }




  }





  /*******************************************************************************
  removeSelectedProperty()
  paramater: Property object => propertyUId
  purpose : To remove selected property 
  **********************************************************************************/

  removeSelectedProperty(property): void {
    const index = this.selectedProperties.indexOf(property);
    const uidIndex = this.tempSelectedPropertyId.indexOf(property.uId);
    if (index >= 0) {
      this.selectedProperties.splice(index, 1);
    }
    if (uidIndex >= 0) {
      this.tempSelectedPropertyId.splice(uidIndex, 1);
    }
  }

  permitFilterStatus(event) {
    let evnt: any = event
    this.route.navigate(['/permit-request/list-all']);
    //{queryParams: { permitStatus: JSON.stringify(evnt) }
  }
  quotesFilterStatus(event) {
    let evnt: any = event
    let obj: any = {
      "page": 0, "pageSize": 10, "filters": [{
        "fieldName": "status", "fieldValue": [evnt.name.split('(')[0]]
      }]
    }
    this.route.navigate(['/quote/list'], { queryParams: { quoteStatus: JSON.stringify(obj) }, skipLocationChange: false, replaceUrl: false });

  }
  issueFilterType(event, typeOfIssue) {

    let evnt: any = event
    let obj: any = {}
    if (typeOfIssue == 'category') {
      obj = { "page": 0, "pageSize": 10, "filters": [{ "fieldName": "jobCategory", "fieldValue": [evnt.name.split('(')[0]] }], issuesReportedFrom: this.ReportedFromDate, issuesReportedTill: this.ReportedTillDate }
    }
    else if (typeOfIssue == 'status') {
      obj = { "page": 0, "pageSize": 10, "filters": [{ "fieldName": "currentStatus", "fieldValue": [evnt.name.split('(')[0]] }], issuesReportedFrom: this.ReportedFromDate, issuesReportedTill: this.ReportedTillDate }
    }
    this.route.navigate(['/issue/list-all']);
    //{ queryParams: { type: JSON.stringify(obj), chart: typeOfIssue }, }
  }
}


export class Property {
  constructor(public uId: string, public name: string, public selected?: boolean) {
    if (selected === undefined) selected = true;
  }
}