import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EpcRating } from '@app/core/models/epc-rating.model';
import { SharedService } from '@app/core/services/shared.service';

@Injectable({
    providedIn: 'root'
})
export class EpcRatingAPI {
    private apiUrl = '';
    constructor(public sharedServices: SharedService, private http: HttpClient) {
        this.apiUrl = this.sharedServices.getAPIUrl();
    }

    /*************************************************************************
    Purpose: Get all epc documents with their Energy power consumption details
    ***************************************************************************/
    getAllEPCRating(epcFiltreCriteria) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
        let body = JSON.stringify(epcFiltreCriteria)
        return this.http.post(this.apiUrl + '/api/EPC/GetAll', body, options);
    }

    /*****************************************************************
getAllProperties()
purpose : this method will get all properties for current user
*******************************************************************/
    getAllSearchProperties(propertyCriteria) {
        const option = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const body = propertyCriteria;
        return this.http.post(this.apiUrl + '/api/Properties/Search', body, option);

    }


    getAllPropertiesForList(propertyCriteria) {
        const option = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const body = propertyCriteria;
        return this.http.post(this.apiUrl + '/api/Properties/GetAllPropertiesForList', body, option);
    }
    /*************************************************************************
    Purpose: Get perticular epc document
    Parameters: epc document uId
    ***************************************************************************/
    getEPCRating(id: string): Observable<EpcRating> {
        return this.http.get<EpcRating>(this.apiUrl + '/api/EPC/Get/' + id);
    }

    /*************************************************************************
    Purpose: add new epc details
    Parameters: epc details from form
    ***************************************************************************/
    addNewEPCRating(epcRating) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(epcRating);
        return this.http.post(this.apiUrl + '/api/EPC/AddNew', body, options);
    }

    /*************************************************************************
    Purpose: to update the existing epc document
    Parameter: epc document and uid
    ***************************************************************************/
    updateEPCRating(epcRating: EpcRating) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
        let body = JSON.stringify(epcRating);
        return this.http.put(this.apiUrl + '/api/EPC/Update/' + epcRating.uId, body, options);
    }

    /*************************************************************************
    Purpose: to share epc rating document
    Parameter: epc document uid and email
    ***************************************************************************/
    shareEpcDocument(uid: string, email: string) {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        return this.http.get(this.apiUrl + '/api/EPC/ShareViaEmail/' + uid + '?emailId=' + email, options)
    }


    /*************************************************************************
    Purpose: to delete the existing user
    Parameter: epc document uId and user version
    ***************************************************************************/
    deleteEPCRating(id: string, version: any): Observable<EpcRating> {
        return this.http.delete<EpcRating>(this.apiUrl + '/api/EPC/Delete/' + id + '?version=' + version);
    }

    /*************************************************************************
     Purpose: to download epc document in excel format
    ***************************************************************************/
    exportAsExcel(excelData): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        let body = JSON.stringify(excelData);
        return this.http.post(this.apiUrl + '/api/EPC/ExportAsExcel', body, { observe: 'response', responseType: 'blob', headers: headers });
    }

    /*****************************************************************
    Parameter:  image to be uplo
    purpose : this method will upload the selected image to server.
    *******************************************************************/
    UploadEpcDocuments(file): Observable<string> {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<string>(this.apiUrl + '/api/EPC/Upload', formData);
    }


    /*****************************************************************
    Parameter:  file name
    purpose : this method will delete the file from database.
    *******************************************************************/
    deleteUploadedDocuments(fileName) {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        return this.http.post(this.apiUrl + '/api/EPC/DeleteUploadedFile?fileName=' + fileName, options)
    }


    /*****************************************************************
    getAllProperties()
    purpose : this method will get all properties for current user
    *******************************************************************/
    getAllProperties(propertyCriteria) {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        // let body = propertyCriteria;
        const body = JSON.stringify(propertyCriteria);
        // //console.log("api filtercriteria", body);
        return this.http.post(this.apiUrl + '/api/Properties/GetAll', body, options);
    }

    /*****************************************************************
    getPropertyById()
    purpose : This property get property details to updat epc rating (only name require to update from this api)
    *******************************************************************/
    getPropertyById(id: string): Observable<any> {
        return this.http.get(this.apiUrl + '/api/Properties/Get/' + id);
    }

    /*************************************************************
     Parameter: 
     Description: This method will get dashboard
    **************************************************************/
    getDashboradData() {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        // let body = JSON.stringify(object)
        return this.http.post(this.apiUrl + '/api/Properties/GetDashboardData', options)
    }
}