import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { RoutingState } from './core/services/history-routing';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    routingState: RoutingState,
    private router: Router,
    private titleService: Title
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`TAP - ${title}`);
        }
      });
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-J6LRLQXSRP',
          {
            'page_path': event.urlAfterRedirects
          }
        );
      }
    });    
    routingState.loadRouting();
  }

  ngOnInit() {
    let userData = JSON.parse(localStorage.getItem("user_data"));
    let token = localStorage.getItem('access_token');
    if ((userData === undefined || userData === null || userData === "") && (token !== undefined || token !== null || token !== "")) {
      this.logoutFunction();
    }
    if (environment.production) {


    }
  }
  /*****************************************************************
logoutFunction()
purpose : this method will logout the user and will remove all stored items
*******************************************************************/
  logoutFunction() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_info');
    localStorage.removeItem('user_data');
    localStorage.removeItem('org-type');
    localStorage.removeItem('property');
  }


}
