import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopnavComponent } from './topnav/topnav.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { MainComponent } from './main/main.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutComponent } from './layout/layout.component';
import { CustomMaterialModule } from '@app/shared/modules/angular-material/material.module';
import { FlexLayoutModule } from "@angular/flex-layout";
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule, MatSlideToggleModule, MatTooltipModule } from '@angular/material';
import { UserNotificationSettingAPI } from '@app/shared/apis/user-notification.api';
import { LayoutService } from './layout.service';
import { NotificationComponent } from './notification/notification.component';
import { UsersAPI } from '@app/shared/apis/users.api';
import { CustomNgxChartsModule } from '@app/shared/modules/ngx-charts/ngx-charts.module';
import { AccountAPI } from '@app/shared/apis/account.api';
import { IssuesAPI } from '@app/shared/apis/issue.api';
import { MomentModule } from 'ngx-moment';
import { ConsentAPI } from '@app/shared/apis/consent.api';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SharedService } from '@app/core/services/shared.service';
import { EventsApi } from '@app/shared/apis/events.api';
import { VisitorApi } from '@app/shared/apis/visitor.api';
import { RedirectComponent } from './redirect/redirect.component';
import { EpcRatingAPI } from '@app/shared/apis/epc-rating.api';
import { MatListModule } from '@angular/material/list';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { FilePreviewIconModule } from '@app/shared/modules/file-preview-icon/file-preview-icon.module';

@NgModule({
  declarations: [LoginComponent, TopnavComponent, SidebarComponent, FooterComponent,
    MainComponent, LayoutComponent, DashboardComponent, NotificationComponent, RedirectComponent],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    CustomMaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatBadgeModule,
    MatTooltipModule,
    MatSlideToggleModule,
    CustomNgxChartsModule,
    MomentModule,
    MatListModule,
    YouTubePlayerModule,
    FilePreviewIconModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 0,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300
    }),
  ],
  exports: [
    LayoutComponent
  ],
  providers: [
    UserNotificationSettingAPI,
    LayoutService,
    UsersAPI,
    AccountAPI,
    IssuesAPI,
    ConsentAPI,
    EventsApi,
    VisitorApi,
    SharedService,
    EpcRatingAPI
  ]
})
export class LayoutModule { }
