import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '@app/core/services/shared.service';
import { StorageService } from '@app/core/services/storage.service';
import { Consents } from '@app/core/models/consent.model';


@Injectable({
    providedIn: 'root'
})
export class ConsentAPI {

    private apiUrl = '';
    constructor(public sharedService: SharedService, private http: HttpClient, private localStorageService: StorageService) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }

    /*****************************************************************
    GetAllCurrentConsentlist()
    purpose : this method will get all Consents from API
    *******************************************************************/
    GetAllConsents(consent) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = consent;
        return this.http.post(this.apiUrl + '/api/Consent/getAll', body, httpOptions);
    }

    /*****************************************************************
getAllProperties()
purpose : this method will get all properties for current user
*******************************************************************/
    getAllSearchProperties(propertyCriteria) {
        const option = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const body = propertyCriteria;
        return this.http.post(this.apiUrl + '/api/Properties/Search', body, option);

    }

    getAllPropertiesForList(propertyCriteria) {
        const option = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const body = propertyCriteria;
        return this.http.post(this.apiUrl + '/api/Properties/GetAllPropertiesForList', body, option);
    }

    /*****************************************************************
    getArchivedConsentList()
    purpose : this method will get all archived Consents from API
    *******************************************************************/
    getAllArchiveConsentList(consentId): Observable<Consents[]> {
        return this.http.get<Consents[]>(this.apiUrl + '/api/Consent/Get/' + consentId);
    }

    /*****************************************************************
    getAllConsentDetails()
    purpose : this method will get the Consents and other Consents statistics to be displayed in Consents dashboard
    *******************************************************************/
    getConsentDetails(consentId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(this.apiUrl + '/api/Consent/Get/' + consentId, httpOptions);
    }

    /*****************************************************************
    addConsent()
    purpose : this method will add a new Consent 
    *******************************************************************/
    addConsent(Consents: Consents) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(Consents);
        return this.http.post(this.apiUrl + '/api/Consent/addNew', body, options);
    }


    /*****************************************************************
    updateConsent()
    param:  Consents to be updated
    purpose : this method will update existing Consents.
    *******************************************************************/
    updateConsent(Consents) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(Consents);
        return this.http.put(this.apiUrl + '/api/Consent/Update/' + Consents.uId, body, options);
    }

    /*****************************************************************
    deleteConsent()
    param:  Consents to be updated
    purpose : this method will update existing Consents.
    *******************************************************************/
    deleteConsent(Consents: Consents) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        // const body = JSON.stringify(Consents);
        return this.http.delete(this.apiUrl + '/api/Consent/Delete/' + Consents.uId + '?version=' + Consents.version);
    }

    /*****************************************************************
    archiveConsent()
    param:  Consents to be archived
    purpose : this method will delete the Consents bt id
    *******************************************************************/
    archiveConsent(id: string) {
        return this.http.get(this.apiUrl + '/api/Consent/archiveConsent?id=' + id);
    }


    /*****************************************************************
    downloadConsentList()
    param:  filter criteria json
    purpose : this method will filter the Consents based on criteria and download it.
    *******************************************************************/
    downloadConsentList(model) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const body = JSON.stringify(model);
        return this.http.post(this.apiUrl + '/api/Consent/ExportAsExcel', body, { observe: 'response', responseType: 'blob', headers: headers });
    }


    /*****************************************************************
    getAllAdvisors()
    purpose : this method will get the list of all advisors
    *******************************************************************/
    getAllAdvisors() {
        return this.http.get(this.apiUrl + '/api/Consent/getAllAdvisors');
    }


    /*****************************************************************
    getAllAdvisors()
    purpose : this method will get the list of all advisors
    *******************************************************************/
    shareViaEmail(uid, emailId) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        return this.http.get(this.apiUrl + '/api/Consent/ShareViaEmail/' + uid + '?emailId=' + emailId);
    }
    /*****************************************************************
    getAllProperties()
    purpose : this method will get all properties for current user
    *******************************************************************/
    getAllProperties(propertyCriteria) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = propertyCriteria;
        return this.http.post(this.apiUrl + '/api/Properties/GetAll', body, httpOptions);
    }

    /*****************************************************************
    getTenantsForProperty()
    param: property id
    purpose : this method will get the tenants accessible to current user for selected property.
    *******************************************************************/
    getTenantsForProperty(propertyId: string) {
        return this.http.get(this.apiUrl + '/api/Properties/GetAllProperyUnitsTenants/' + propertyId);
    }

    /*****************************************************************
    getAllCategories()
    purpose : this method will get the list of categories
    *******************************************************************/
    getAllCategories() {
        return this.http.get(this.apiUrl + '/api/Consent/getAllCategories');
    }

    /*****************************************************************
    UploadIssueImage()
    param:  image to be uploaded
    purpose : this method will upload the selected image to server.
    *******************************************************************/
    UploadConsentFile(file): Observable<string> {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<string>(this.apiUrl + '/api/Consent/Upload', formData);
    }
    /*****************************************************************
        DeleteUploadedFile()
        param:  image to be deleted
        purpose : this method will delete the selected image to server.
        *******************************************************************/
    DeleteUploadedFile(fileName: string) {
        let body = {}
        //console.log(fileName);
        return this.http.post(this.apiUrl + '/api/Consent/DeleteUploadedFile?fileName=' + fileName, body);
    }

    /*****************************************************************
        getConsentTypeList()
        purpose : this method will get List of Consent types
        *******************************************************************/
    getConsentTypeList() {
        return this.http.get(this.apiUrl + '/api/Consent/GetConsentTypes');
    }

    /*****************************************************************
        getConsentStatusList()
        purpose : this method will get List of Consent Status types
        *******************************************************************/
    getConsentStatusList() {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(this.apiUrl + '/api/Consent/GetConsentStatusTypes', httpOptions);
    }

    /*****************************************************************
        getAdvisorTypeList()
        purpose : this method will get list of types of Advisor
        *******************************************************************/
    getAdvisorTypeList() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = {};
        return this.http.get(this.apiUrl + '/api/Consent/GetConsentAdvisorTypes', httpOptions);
    }


    getAllPropertyUnits(propertyUid): Observable<any> {
        return this.http.get(this.apiUrl + '/api/Properties/GetAllProperyUnits/' + propertyUid);
    }
}    
