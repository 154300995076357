
import swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountAPI } from '@app/shared/apis/account.api';
import { SharedService } from '@app/core/services/shared.service';
import { StorageService } from '@app/core/services/storage.service';
import { Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loading = false;
  public hide = true;
  public showSpinner = false;
  public userInfo: any;
  public action: any;
  public redirectURl = '';


  constructor(public sharedService: SharedService, private accountAPI: AccountAPI, private router: Router, private storageService: StorageService,
    private routerParams: ActivatedRoute) {
    routerParams.queryParams.subscribe(
      params => {
        let action = params['action'];
        if (action === 'logout') {
          window.location.href = '/login'
        }
      });
    
    this.routerParams.queryParams.subscribe(params => {
      this.redirectURl = params["redirectURl"] || "";
    });
  }

  ngOnInit() {
   this.sharedService.isUserLoggedIn = false;
  }

  login(mail, pwd) {
    this.loading = true;
    this.showSpinner = true;
    let loginObject = {
      userName: mail,
      password: pwd,
    }
    this.accountAPI.userLogin(loginObject).subscribe(
      response => {
        localStorage.setItem('access_token', response['access_token']);
        this.getUser();
      },
      err => {
        if (err.status === 403) {
          swal({
            title: 'Oops...',
            position: 'center',
            text: "Username or password is incorrect. Please try again.",
            type: 'error'
          });
        } else if (err.status === 500 && err.message === "Server Error") {
          swal({
            title: 'Oops...',
            position: 'center',
            text: "We're currently updating the system. Please try again later.",
            type: 'error'
          });
        } else {
          swal({
            title: 'Oops...',
            position: 'center',
            text: "Not able to connect to the TAP system. Please check your network or firewall settings.",
            type: 'error'
          });
        }


        //console.log(err);
        this.loading = false;
        this.showSpinner = false;
      }
    );
  }

  getUser() {
    //GetUserInfoNew
    this.accountAPI.getUserData().subscribe(
      response => {
        let loggedInUserInfo = response;
        localStorage.setItem('user_info', JSON.stringify(loggedInUserInfo));
        this.getUserInformation();
      }, error => {
        
      });
  }

  getUserInformation() {
    // getUserInfo
    this.sharedService.isAdminUser = false;
    this.accountAPI.getUserInfo().subscribe(
      response => {
        this.userInfo = response;
        localStorage.setItem('user_data', JSON.stringify(this.userInfo));
        localStorage.setItem('org-type', this.userInfo.orgType);
        if (this.userInfo.orgType === 'SuperAdmin' || this.userInfo.orgType === 'ClientAdmin') {
          this.sharedService.isAdminUser = true;
          }
        if (this.redirectURl !== "") {
          window.location.href = this.redirectURl;
        } else {
          if (this.userInfo.orgType === 'ClientAdmin') {
            this.router.navigate(['permit-request/list-all']);
          } else if (this.userInfo.landingPage === "permit-request") {
            this.router.navigate(['permit-request/list-all']);
          } else if (this.userInfo.landingPage === "property-portal") {
            this.router.navigate(['property/list-all']);
          } else if (this.userInfo.landingPage === "issues") {
            this.router.navigate(['issue/list-all']);
          } else {
            this.router.navigate(['dashboard']);
          }
        }
        this.sharedService.isUserLoggedIn = true;
      }, error => {
        
      });
  }

}
