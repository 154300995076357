import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { SharedService } from '@app/core/services/shared.service';
import { trigger, transition, animate, style } from '@angular/animations'
import { UsersAPI } from '@app/shared/apis/users.api';
import { LayoutService } from '../layout.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EventsApi } from '@app/shared/apis/events.api';
import { Location, LocationStrategy } from "@angular/common";
import { GlobalSearchService } from '@app/core/services/global-search.service';
import { UserNotificationSettingAPI } from '@app/shared/apis/user-notification.api';
import { StorageService } from '@app/core/services/storage.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('500ms ease-in', style({ opacity: '1' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ opacity: '0' }))
      ])
    ])
  ]
})

export class LayoutComponent implements OnInit {
  showIcon = true;
  searchValue = false;
  data = 6;
  value = ''
  public showSearch = false;
  public currentLocation;
  public timeout = null;
  public searchText: any;
  public dismissNotify: any;
  @Input() notificationLength;
  @ViewChild('keywordsInput') private nameField: ElementRef;
  public permitVideoId: any;
  public videoPlayer = false;
  userData: any;
  public globalSearchListner: any;
  public showRedirectPanels = false;
  usersAPI: any;
  constructor(public sharedService: SharedService, private globalSearchService: GlobalSearchService, public layoutService: LayoutService, private router: Router, private route: ActivatedRoute, public location: Location, public eventsAPI: EventsApi, location2: LocationStrategy, public notificationApi: UserNotificationSettingAPI, public storage: StorageService,) {
    location2.onPopState(() => {
      this.closeSearch();
      // alert(window.location);
    });
  }

  ngOnInit() {

    this.sharedService.showSearch;
    this.currentLocation = window.location.pathname;
    if (this.currentLocation === '/dashboard') {
      this.showSearch = false;
    }
    this.checkUser();
    this.globalSearchListner = this.globalSearchService.globalShowSearchEventListner().subscribe(
      event => {
        this.showSearch = event;
      }
    )
  }


  checkUser() {
    let orgType = localStorage.getItem('org-type');
    if (orgType === 'SuperAdmin' || orgType === 'ClientAdmin') {
      this.showRedirectPanels = true;
    }
  }

  closeSearch() {
    this.value = '';
    this.searchText = "";
  }

  clickedInside($event: Event) {
    this.focusSearch();
    $event.preventDefault();
    $event.stopPropagation();  // <- that will stop propagation on lower layers
  }

  ngOnDestroy() {
    this.globalSearchListner.unsubscribe();
  }

  focusSearch() {
    this.showSearch = !this.showSearch;
    setTimeout(() => {
      this.nameField.nativeElement.focus();
    }, 0);

  }

  inputChangeEvent(event) {
    event.target.value;
    //reduced api calls for search properties by time out method
    clearTimeout(this.timeout);
    this.timeout = setTimeout(t => {
      this.globalSearchService.globalSearchEventEmitter.emit({ 'value': event.target.value });
    }, 400);

  }

  resetSearch() {
    this.searchText = "";
    this.globalSearchService.globalSearchEventEmitter.emit({ 'value': "" });
  }


  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_info');
    localStorage.removeItem('user_data');
    localStorage.removeItem('org-type');
    localStorage.removeItem('property');

    // localStorage.clear();
    // this.router.navigate(['/login']);
    this.router.navigate(['/login'], { queryParams: { action: 'logout' } });

  }


  playVideo(videoType) {
    if (videoType === 'inviting') {
      this.permitVideoId = "9NmTQoxODB8"
    } else if (videoType === 'basic') {
      this.permitVideoId = "mpwFgpAoWjE"
    } else if (videoType === 'access') {
      this.permitVideoId = "gOFV4YhFslc"
    } else if (videoType === 'equipment') {
      this.permitVideoId = "R69QWh10BWk"
    } else if (videoType === 'questions') {
      this.permitVideoId = "SxAeZSzHSIY"
    } else if (videoType === 'workers') {
      this.permitVideoId = "UDIKF31yWuc"
    } else if (videoType === 'preview') {
      this.permitVideoId = "XTQWoQ3_b7A"
    } else if (videoType === 'status') {
      this.permitVideoId = "Upp0mB_BWHk"
    } else if (videoType === 'cloning') {
      this.permitVideoId = "Ougd2hQlD7c"
    }
    this.videoPlayer = true;
  }

  stopVideo() {
    this.videoPlayer = false;
  }

  openNewVideo(permitVideoId) {
    this.videoPlayer = false;
    window.open(
      'https://youtu.be/' + permitVideoId
    );
  }


  redirectToAdminPanel() {
    let authToken = this.storage.getValue('access_token') || '';
    if (authToken !== '') {
      let url = this.sharedService.getAdminUrl();
      let adminPortalUrl = url + '/redirect?authToken=' + authToken + '&redirectURl=' + encodeURI(url + '/properties/dashboard');
      window.open(adminPortalUrl);
    }
  }

}

