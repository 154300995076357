import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UsersAPI } from '@app/shared/apis/users.api';
import { SharedService } from '@app/core/services/shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  public version = '4.53';
  isIssue = false;
  isConsent = false;
  isPermitToWork = false;

  isPermitToWorkNew = false;
  isEnergy = false;
  isDocuments = false;
  isEvents = false;
  isEPC = false;
  isUsers = false;
  isFootfall = false;
  isVisitors = false;
  isWasteRecycle = false;
  isAssignVendorToJobCategory = false;
  isAddPropertyResource = false;
  isStackingPlan = false;
  isPermissionToAccess = false;
  isParcel = false;
  isPropertyPortals = false;
  showMeter = false;
  showPropertyPortals = false;
  showDashboard = true;
  showSummaryReport = false;
  showAdminSidebar = false;
  adminMenuItems = false;
  isSMS = false;
  showHome = true;
  isVendor = false;

  @Output() closeSidenav = new EventEmitter();
  User: any;
  public PropertyManagerLogin = false;
  public ClientAdminLogin = false;
  users = false;
  sustain = false;
  isQuote = false

  constructor(private usersAPI: UsersAPI, public sharedService: SharedService) { }

  ngOnInit() {
    this.getUserInfo();
    this.checkUser();

  }

  getUserInfo() {
    let loggedInUser = JSON.parse(localStorage.getItem("user_data"));
    if (loggedInUser !== undefined && loggedInUser !== null && loggedInUser !== "") {
      this.User = loggedInUser
      this.setFeatures(this.User);
    } else {
      this.usersAPI.getUserInfo().subscribe(
        response => {
          this.User = response
          this.setFeatures(this.User);
        });
    }
  }

  checkUser() {
    let orgType = localStorage.getItem('org-type');
    if (orgType === 'SuperAdmin' || orgType === 'PropertyManager') {
      this.sharedService.adminOrg = true
      this.showAdminSidebar = true;
    }

    if (orgType === 'Vendor') {
      this.showHome = false;
    }
  }



  setFeatures(user) {
    // let orgtype = localStorage.getItem('org-type');
    if (user.orgType === "SuperAdmin") {
      this.showMeter = true;
      this.showSummaryReport = true;
      this.getMenu();
    } else if (user.orgType === "Vendor") {
      this.showDashboard = false;
      this.showMeter = false;
      this.isVendor = true;
      this.getMenu();
    } else if (user.orgType === "PropertyManager") {
      this.PropertyManagerLogin = true;
      this.showSummaryReport = true;
      this.getMenu();
    } else if (user.orgType === "Tenant") {
      this.showSummaryReport = true;
      this.getMenu();
    } else if (user.orgType === "ClientAdmin") {
      this.ClientAdminLogin = true;
      this.showDashboard = false;
      this.isStackingPlan = true;
      this.isPermitToWork = true;
      this.isUsers = true;
      this.isVisitors = true;
      this.showSummaryReport = true;
    }
  }

  showHelpdeskMenu = false;
  showPermitsMenu = false;

  helpdeskMenu() {
    this.showHelpdeskMenu = !this.showHelpdeskMenu;
  }

  permitsMenu() {
    this.showPermitsMenu = !this.showPermitsMenu;
  }

  usersMenu() {
    this.users = !this.users
  }

  sustainabilityMenu() {
    this.sustain = !this.sustain
  }

  getMenu() {
    //console.log('features : ' + this.User.featureList)
    for (let i = 0; i < this.User.featureList.length; i++) {
      if (this.User.featureList[i] === 'Issue') {
        this.isIssue = true;
      } else if (this.User.featureList[i] === 'Consent') {
        this.isConsent = true;
      } else if (this.User.featureList[i] === 'Documents') {
        this.isDocuments = true;
      } else if (this.User.featureList[i] === 'Energy' && this.User.orgType !== 'Tenant') {
        this.isEnergy = true;
      } else if (this.User.featureList[i] === 'Events') {
        this.isEvents = true;
      } else if (this.User.featureList[i] === 'EPC') {
        this.isEPC = true;
      } else if (this.User.featureList[i] === 'Footfall') {
        this.isFootfall = true;
      } else if (this.User.featureList[i] === 'PermitToWork') {
        this.isPermitToWork = true;
      } else if (this.User.featureList[i] === 'PermitToWorkNew') {
        this.isPermitToWorkNew = true;
      } else if (this.User.featureList[i] === 'Users') {
        this.isUsers = true;
      } else if (this.User.featureList[i] === 'Visitors') {
        this.isVisitors = true;
      } else if (this.User.featureList[i] === 'WasteRecycle') {
        this.isWasteRecycle = true;
      } else if (this.User.featureList[i] === 'AddPropertyResource') {
        this.isAddPropertyResource = true;
      } else if (this.User.featureList[i] === 'AssignVendorToJobCategory') {
        this.isAssignVendorToJobCategory = true;
      } else if (this.User.featureList[i] === 'StackingPlan') {
        this.isStackingPlan = true;
      } else if (this.User.featureList[i] === 'PermissionToAccess') {
        this.isPermissionToAccess = true;
      } else if (this.User.featureList[i] === 'Parcel') {
        this.isParcel = true;
      } else if (this.User.featureList[i] === 'PropertyPortals') {
        this.isPropertyPortals = true;
      }
      else if (this.User.featureList[i] === 'Quote') {
        this.isQuote = true;
      }
      else if (this.User.featureList[i] === 'Portals') {
        this.showPropertyPortals = true;
      }
      else if (this.User.featureList[i] === 'SMS') {
        this.isSMS = true;
      }
    }
  }
}


