import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { HttpErrorInterceptor } from './http-interceptors/http-error.interceptor';
import { TokenInterceptor } from './http-interceptors/auth-token.interceptor';
import { AccountAPI } from '../shared/apis/account.api';
import { FlexLayoutModule } from "@angular/flex-layout";
@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    FlexLayoutModule
  ],
  providers: [
    AccountAPI,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
})
export class CoreModule { }
