import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SharedService } from '@app/core/services/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ngx-chart',
  templateUrl: './ngx-chart.component.html',
  styleUrls: ['./ngx-chart.component.css']
})
export class NgxChartComponent implements OnInit {

  @Input() chartType?= '';
  @Input() chartData?: any;

  @Input() showXAxis?= true;
  @Input() showYAxis?= true;
  @Input() gradient?= false;
  @Input() showLegend?= true;
  @Input() legendPosition = '';
  @Input() showXAxisLabel?= true;
  @Input() xAxisLabel?= '';
  @Input() showYAxisLabel?= true;
  @Input() yAxisLabel?= ''; 
  @Input() chartWidth?: any;
  @Input() chartHeight?: any;
  @Input() legendTitle: string;
  @Input() view: any[] = [];
  @Input() animations: boolean = true;

  width: number = 700;
  height: number = 300;

  public xAxisTickFormattingFn = this.xAxisTickFormatting.bind(this);
  // public xAxisTickFormattingForEnergy = this.xAxisTickFormattingForEnergyFunction.bind(this);
  // public xAxisTickFormattingForVisitorLineChart = this.xAxisTickFormattingVisitors.bind(this);

  @Output() permitData = new EventEmitter<string>();
  
  @Input() colorScheme?= {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#FFCC00', '#32c1b6', '#a9aed9', '#0af248']
  };

  @Input() customColors: any[] = [];

  @Input() colorBlue?= {
    domain: ['#459efd']
  };
  @Input() explodeSlices?= false;
  @Input() doughnut = false;
  @Input() showLabels = true;

  @Input() dataLoaded?= false;

  constructor(public sharedService: SharedService, public router: Router) {

  }
  ngOnInit() {
    if (this.chartWidth && this.chartHeight) {
      this.view = [Number(this.chartWidth), Number(this.chartHeight)];
    } else {
      this.view = undefined;
    }    
  }

  xAxisTickFormatting(val) {
    return val.toLocaleString() + '%';
  }

  xAxisTickFormattingVisitors(val) {
    if (val < 12) {
      return val.toLocaleString() + ":00 AM"
    } else {
      return val.toLocaleString() + ":00 PM"
    }
  }

  yAxisTickFormattingEPC(val) {
    if( val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }

  yAxisTickFormattingVisitors(val) {
    if( val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }

  xAxisTickFormattingEPC(val) {
    if( val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }

  xAxisTickFormattingIssue(val) {
    if( val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }


  onSelect(event) {
    // //console.log('Before', this.chartData);
    // this.chartData.forEach(chartdata => {
    //   chartdata.series.forEach(series=> {
    //     if(event === series.name) {
    //       let index = chartdata.series.indexOf(series.name) + 1;
    //       chartdata.series.splice(index, 1);
    //     }
    //   })
    // });
    //console.log('Before', event);
    if (event.value !== 0) {

       
      //console.log("Status selected", event)
      this.permitData.emit(event);
    }
  }

  onSelectPermitReqBar(event)
  {
    if (event.value !== 0) {

       
      //console.log("Status selected", event)
      this.permitData.emit(event);
    }
  }

  onSelectBar(event) {
    if (event.value !== 0) {
      this.sharedService.epcRatingValue = event.name;
      localStorage.setItem("epcRatingValue", event.name)
      this.router.navigate(['/epc-rating/list']);
    }
  }

// Permit-To-Work 
    onSelectPermit(event) {

  }

  onSelectPermitBar(event) {
    if (event.value !== 0) {
      this.sharedService.permitStat = event.name;
      localStorage.setItem("permitStat", event.name)
      this.router.navigate(['permit-to-work/list-all']);
    }
  }

}
