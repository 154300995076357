import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './layout/layout.module';

import {NgxMaskModule} from 'ngx-mask'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatCardModule, MatDividerModule, MatPaginatorModule, MatSelectModule, MatToolbarModule } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { RoutingState } from './core/services/history-routing';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ExportAsModule } from 'ngx-export-as';
import { MatPaginatorGotoComponent } from './shared/modules/mat-paginator-goto/mat-paginator-goto.component';
import { FormsModule } from '@angular/forms';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    CoreModule,
    MatCardModule,
    MatDividerModule,
    LayoutModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    NgxMaskModule.forRoot(),
    ExportAsModule,
    MatPaginatorModule,
    MatSelectModule, 
    MatToolbarModule,
    FormsModule,
  ],
  providers: [RoutingState,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, 
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' },
    {
      provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
