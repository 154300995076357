import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import {
  MatButtonModule, MatNativeDateModule, MatIconModule, MatSidenavModule, MatTooltipModule,
  MatListModule, MatToolbarModule, MatCardModule, MatInputModule, MatSelectModule, MatRadioModule, MatCheckboxModule, MatTableModule,
  MatDatepickerModule, MatPaginatorModule, MatDialogModule, MatExpansionModule, MatDividerModule, MatChipsModule, MatMenuModule,
  MatAutocompleteModule, MatSnackBarModule, MatProgressSpinnerModule, MatTabsModule, MatSlideToggleModule, MatProgressBarModule,
} from '@angular/material';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
  imports: [CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatIconModule,
    MatTooltipModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatListModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTableModule,
    MatDialogModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatChipsModule,
    MatMenuModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatStepperModule
  ],
  exports: [
    CommonModule,
    MatButtonModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatListModule,
    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTableModule,
    MatDialogModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatChipsModule,
    MatMenuModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatProgressBarModule
  ],
})
export class CustomMaterialModule { }
