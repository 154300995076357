// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appConstants: {
    portalRedirectURL: 'https://dashboard-test.tap-in.co.uk',

    //test server envirnonments
    apiUrl: 'https://tap-api-test.azurewebsites.net',
    appUrl: 'https://dashboard-test.tap-in.co.uk',
    blobUrl: 'https://tapintest.blob.core.windows.net',
    adminUrl: 'https://admin-test.tap-in.co.uk'

  }
};
