import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '@app/core/services/shared.service';
import { Events } from '@app/core/models/events.model';
@Injectable({
    providedIn: 'root'
})
export class EventsApi {
    private apiUrl = '';
    public selectedProps: any;

    private authToken = "";
    constructor(public sharedService: SharedService, private http: HttpClient) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }


    /*****************************************************************
   addNewEvent()
   param: new event to be added
   purpose : this method will save the new events to db
   *******************************************************************/
    addNewEvent(event: Events) {

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(event);
        return this.http.post(this.apiUrl + '/api/Events/AddNew', body, options);
    }

    /*****************************************************************
selfCheckIn()
param: new event to be added for self check-in
purpose : this method will save the new events to db
*******************************************************************/
    selfCheckIn(event: Events) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(event);
        return this.http.post(this.apiUrl + '/api/Events/SelfCheckInVisitor', body, options);
    }


    /*****************************************************************
getAllProperties()
purpose : this method will get all properties for current user
*******************************************************************/
    getAllSearchProperties(propertyCriteria) {
        const option = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const body = propertyCriteria;
        return this.http.post(this.apiUrl + '/api/Properties/Search', body, option);

    }

    getAllPropertiesForList(propertyCriteria) {
        const option = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const body = propertyCriteria;
        return this.http.post(this.apiUrl + '/api/Properties/GetAllPropertiesForList', body, option);
    }

    /*****************************************************************
      uploadEventDocument()
      param: event document to be added
      purpose : this method will upload the event document to db
      *******************************************************************/
    uploadEventDocument(fileData) {
        const formData = new FormData();
        formData.append('file', fileData)

        return this.http.post(this.apiUrl + '/api/Events/Upload', formData);
    }
    /*****************************************************************
            DeleteUploadedFile()
            param:  image to be deleted
            purpose : this method will delete the selected image to server.
            *******************************************************************/
    DeleteUploadedFile(fileName: string) {

        let body = {}
        //console.log(fileName);
        //console.log("api side deleted");
        return this.http.post(this.apiUrl + '/api/Events/DeleteUploadedFile?fileName=' + fileName, body);
    }

    /*****************************************************************
     getEvent()
     param: event id
     purpose : this method will get the event by id
   *******************************************************************/
    getEvent(id: string): Observable<Events> {
        return this.http.get<Events>(this.apiUrl + '/api/Events/Get/' + id);
    }

    /*****************************************************************
    updateEvent()
    param:  event to be updated
    purpose : this method will update existing event.
    *******************************************************************/
    updateEvent(event: Events) {

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }
        let body = JSON.stringify(event);
        return this.http.put(this.apiUrl + '/api/Events/Update/' + event.uId, body, options);
    }
    /*************************************************************************
      Purpose: Get all user to display list on event dropdown
      ***************************************************************************/
    getAllUsers() {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.apiUrl + '/api/Users/GetAll', options);
    }

    /*************************************************************************
      Purpose: Get all user to display list on event dropdown
      ***************************************************************************/
    getAllUsersWithSuggestion(object) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(object);
        return this.http.post(this.apiUrl + '/api/Users/GetAll', body, options);
    }

    /*****************************************************************
   deleteEvent()
   param:  event to be delete
   purpose : this method will delete the event by id
   *******************************************************************/
    deleteEvent(id: string, version: any) {
        return this.http.delete(this.apiUrl + '/api/Events/Delete/' + id + '?version=' + version);
    }

    /*****************************************************************
  getAllTenants()
     purpose : this method will get the tenant by id
  *******************************************************************/
    getAllTenants(id: string) {
        return this.http.get(this.apiUrl + '/api/Properties/GetAllProperyUnitsTenants/' + id);
    }
    /*****************************************************************
 getProperty()
 param: uId
    purpose : this method will get the property by id
 *******************************************************************/
    getProperty(uId: string) {
        return this.http.get(this.apiUrl + '/api/Properties/Get/' + uId);
    }
    /*****************************************************************
getAllResource()
param: propertyuId
   purpose : this method will get the property by id
*******************************************************************/
    getAllResource(propertyUID: string) {
        return this.http.get(this.apiUrl + '/api/Events/GetAllResources?propertyUId=' + propertyUID);
    }
    /*****************************************************************
    GetAllEvents()
    purpose : this method will get all events from API
    *******************************************************************/
    getAllEvents(EventObj) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(EventObj);
        return this.http.post(this.apiUrl + '/api/Events/GetAll', body, options);
    }
    /*****************************************************************
       getAllEventsWithPage()
       param: page
       purpose : this method will call api to get all Events with pageSize
   *******************************************************************/
    public getAllEventsWithPage(page) {
        let object = {
            "page": page
        }
        return this.http.post(this.apiUrl + '/api/Events/GetAll', object);
    }

    /*****************************************************************
       getAllProperties()
       purpose : this method will get all properties for current user
       *******************************************************************/
    getAllProperties(propObj) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.apiUrl + '/api/Properties/GetAll', propObj, options);
    }

    getPropertyTenants(uid) {
        return this.http.get(this.apiUrl + '/api/Properties/GetAllProperyUnitsTenants/' + uid);
    }

    getPropertyByUid(propertyUid): Observable<any> {
        return this.http.get(this.apiUrl + '/api/Properties/Get/' + propertyUid)
    }

    getAllPropertyUnits(propertyUid): Observable<any> {
        return this.http.get(this.apiUrl + '/api/Properties/GetAllProperyUnits/' + propertyUid);
    }

    getUserData() {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        return this.http.get(this.apiUrl + '/api/Users/GetUserInfoNew', options);
    }

    // checkInVisitorUsingQR(qrCodeId): Observable<any> {
    //     return this.http.get(this.apiUrl + '/api/Events/CheckInVisitorUsingQR/' + qrCodeId );
    // }

    getVisitorDetailsUsingQR(qrCodeId): Observable<any> {
        return this.http.get(this.apiUrl + '/api/Events/GetVisitorQRCodeDetails/' + qrCodeId);
    }

    checkInVisitorUsingQR(apiObject) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.apiUrl + '/api/Events/CheckInVisitorUsingQR', apiObject, options);
    }

    checkOutVisitorUsingQR(qrCodeId): Observable<any> {
        return this.http.get(this.apiUrl + '/api/Events/CheckOutVisitorUsingQR/' + qrCodeId);
    }

    checkInVisitorForUnmannedProperty(apiObject){
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.apiUrl + '/api/Events/CheckInVisitorForUnmannedProperty', apiObject, options);
    }

    checkOutVisitorForUnmannedProperty(apiObject){
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.apiUrl + '/api/Events/CheckOutVisitorForUnmannedProperty', apiObject, options);
    }

    checkIn(object) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.put(this.apiUrl + '/api/Events/CheckInVisitors/' + object.uId, JSON.stringify(object), options);
    }

    getPropertyManager(uId: string) {
        return this.http.get(this.apiUrl + '/api/PropertyManagers/Get/' + uId);
    }
}