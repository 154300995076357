import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilePreviewIconComponent } from './file-preview-icon.component';
@NgModule({
    declarations: [FilePreviewIconComponent],
    imports: [
        CommonModule
    ],
    exports: [FilePreviewIconComponent],
    providers: []
})
export class FilePreviewIconModule { }
