import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '@app/core/services/shared.service';
import { Issue } from '@app/core/models/issue.model';


@Injectable({
    providedIn: 'root'
})
export class IssuesAPI {

    public apiParameter: any;
    listPageIndex;
    listPageSize;
    filter: any = null;
    searchString = '';
    querySearch = "";
    selectedProps: any;
    selectedTenant: any;
    private apiUrl = '';
    public filterApplied = false;
    public filterCriteria: any;
    public issueAction = ''
    private authToken = '';
    constructor(public sharedService: SharedService, private http: HttpClient) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }


    /*****************************************************************
    GetAllCurrentIssuelist()
    purpose : this method will get all issues from API
    *******************************************************************/
    GetAllCurrentIssuelist(issueFilterCriteria) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(issueFilterCriteria);
        return this.http.post(this.apiUrl + '/api/Issues/getAll', body, options);
    }

    getAllResourcesForProperty(propertyUid): Observable<any> {
        return this.http.get(this.apiUrl + '/api/Properties/GetAllPropertyResource' + '?propertyUId=' + propertyUid);
    }

    /*****************************************************************
   GetAllCurrentIssuelist()
   purpose : this method will get all issues from API
   *******************************************************************/
    GetAllCurrentIssuelistNew(issueFilterCriteria) {

        const data = JSON.stringify(issueFilterCriteria);
        const options = {
            // body: data,
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        // return this.http.request('GET', this.apiUrl + '/api/Issues/getAllNew', options);

        let req = new HttpRequest('GET', this.apiUrl + '/api/Issues/getAllNew', options);
        let newReq = req.clone({ body: data });
        return this.http.request(newReq);
    }

    /*****************************************************************
    getAllArchiveIssueList()
    purpose : this method will get all archieved issues from API
    *******************************************************************/
    getAllArchiveIssueList(): Observable<Issue[]> {
        return this.http.get<Issue[]>(this.apiUrl + '/api/Issues/getAllArchivedIssues');
    }

    /*****************************************************************
    getAllIssueDetails()
    purpose : this method will get the issues and other issue statistics to be displayed in issue dashboard
    *******************************************************************/
    getAllIssueDetails() {
        return this.http.get(this.apiUrl + '/api/Issues/getAllIssueDetails');
    }


    /*****************************************************************
GetIssueStatusTypes()
purpose : this method will GetIssueStatusTypes for current user
*******************************************************************/
    GetIssueStatusTypes() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authToken
            })
        };
        //   const body = {};
        return this.http.get(this.apiUrl + '/api/Issues/GetIssueStatusTypes', httpOptions);

    }
    /*****************************************************************
    getAllProperties()
    purpose : this method will get all properties for current user
    *******************************************************************/
    getAllProperties(propertyCriteria) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authToken
            })
        };
        // //console.log(this.authToken)
        // const body = propertyCriteria;
        let body = JSON.stringify(propertyCriteria);
        // //console.log('api criteriaValue' + propertyCriteria);
        return this.http.post(this.apiUrl + '/api/Properties/GetAll', body, httpOptions);

    }

    getAllPropertiesForList(propertyCriteria) {
        const option = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const body = propertyCriteria;
        return this.http.post(this.apiUrl + '/api/Properties/GetAllPropertiesForList', body, option);
    }


    /*****************************************************************
 getAllProperties()
 purpose : this method will get all properties for current user
 *******************************************************************/
    getAllSearchProperties(propertyCriteria) {
        const option = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const body = propertyCriteria;
        return this.http.post(this.apiUrl + '/api/Properties/Search', body, option);

    }

    /*****************************************************************
    getTenantsForProperty()
    param: property id
    purpose : this method will get the tenants accessible to current user for selected property.
    *******************************************************************/
    getTenantsForProperty(propertyId: string) {
        return this.http.get(this.apiUrl + '/api/Properties/GetAllProperyUnitsTenants/' + propertyId);
    }

    /*****************************************************************
    getTenantsForProperty() autocomplete
    param: property id
    purpose : this method will get the tenants accessible to current user for selected property.
    *******************************************************************/
    getTenantsForProperties(tenCriteria) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.authToken
            })
        };
        // //console.log(this.authToken)
        const body = tenCriteria;
        return this.http.post(this.apiUrl + '/api/Tenants/GetAll', body, httpOptions);
    }

    /*****************************************************************
    getAllJobCategories()
    purpose : this method will get the list of categories
    *******************************************************************/
    getAllJobCategories() {
        return this.http.get(this.apiUrl + '/api/Issues/GetAllJobCategories');
    }

    /*****************************************************************
    getAllVendorsForJobCategory()
    purpose : this method will get the list of categories
    *******************************************************************/
    //  
    getAllVendorsForJobCategory(jobCategoryUid, propertyUid) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.apiUrl + '/api/Issues/GetAllVendorsForJobCategory?jobCateogryUId='
            + jobCategoryUid + '&propertyUId=' + propertyUid, options);

    }

    /*****************************************************************
    getAllVendorsForAllJobCategories()
    purpose : this method will get the list of categories
    *******************************************************************/
    //  
    getAllVendorsForAllJobCategories() {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = '';
        return this.http.post(this.apiUrl + '/api/Issues/GetAllVendorsForAllJobCategories', body, options);

    }

    /*****************************************************************
    getIssue()
    param: vender details
    purpose : this method will assign vendor to job catagories
    *******************************************************************/

    assignVendorToJobCategories(vendorDetails) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(vendorDetails);
        return this.http.post(this.apiUrl + '/api/Issues/AssignVendorToJobCategory', body, options);
    }

    /*****************************************************************
      getUsers()
      purpose : this method will get the list of users (for sending alerts) for the selected property and tenant. API will be called after 3 text has been inputted.
      *******************************************************************/
    getUsers(userNameText) {
        return this.http.get(this.apiUrl + '/api/Issues/getUsers?userNameText=' + userNameText);
    }

    /*****************************************************************
    addNewIssue()
    param: new issue to be added
    purpose : this method will save the new issue to db
    *******************************************************************/
    addNewIssue(issue) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(issue);
        return this.http.post(this.apiUrl + '/api/Issues/AddNew', body, options);
    }


    /*****************************************************************
     getIssue()
     param: issue id
     purpose : this method will get the issue by id
   *******************************************************************/
    getIssue(uId: string): Observable<Issue> {
        return this.http.get<Issue>(this.apiUrl + '/api/Issues/Get/' + uId);
    }

    /*****************************************************************
     getIssue()
     param: issue id
     purpose : this method will get the issue by id
   *******************************************************************/
    assignIssueVendor(assignIssueVendorObj) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(assignIssueVendorObj);
        return this.http.put(this.apiUrl + '/api/Issues/AssignVendor/' + assignIssueVendorObj.issueUId, body, options);
    }
    /*****************************************************************
    updateIssue()
    param:  issue to be updated
    purpose : this method will update existing issue.
    *******************************************************************/
    updateIssue(issue: Issue) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(issue);
        return this.http.put(this.apiUrl + '/api/Issues/Update/' + issue.uId, body, options);
    }

    /*****************************************************************
    deleteIssue()
    param:  issue to be archived and it's version
    purpose : this method will delete the issue bt id
    *******************************************************************/
    deleteIssue(uId: string, version: number) {
        return this.http.delete(this.apiUrl + '/api/Issues/Delete/' + uId + '?version=' + version.toString());
    }

    /*****************************************************************
shareIssue()
param:  issue to be shared and email to be shared with
purpose : this method will delete the issue bt id
*******************************************************************/
    shareIssue(uId: string, email) {
        return this.http.get(this.apiUrl + '/api/Issues/ShareViaEmail/' + uId + '?emailId=' + email);
    }

    /*****************************************************************
    getIssueDetails()
    param:  issue to be archived
    purpose : this method will delete the issue bt id
    *******************************************************************/
    getIssueDetails(id: string): Observable<Issue> {
        return this.http.get<Issue>(this.apiUrl + '/api/Issues/get/' + id);
    }

    /*****************************************************************
    downloadIssueList()
    param:  filter criteria json
    purpose : this method will filter the issues based on criteria and download it.
    *******************************************************************/
    downloadIssueAsExcel(model) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const body = JSON.stringify(model);
        return this.http.post(this.apiUrl + '/api/Issues/ExportAsExcel', body, { observe: 'response', responseType: 'blob', headers: headers });
    }

    /*****************************************************************
    getAllContractors()
    purpose : this method will get the list of all contractors
    *******************************************************************/
    getAllContractors() {
        return this.http.get(this.apiUrl + '/api/Issues/getAllContractors');
    }

    /*****************************************************************
   getContracotrsByCategories()
   purpose : this method will get the list of all contractors by category
   *******************************************************************/
    getContracotrsByCategories() {
        return this.http.get(this.apiUrl + '/api/Issues/getContracotrsByCategories');
    }

    /*****************************************************************
    UpdateContractorDetails()
    param:  contractor json to be updated
    purpose : this method will filter the issues based on criteria and download it.
    *******************************************************************/
    UpdateContractorDetails(contractor) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(contractor);
        return this.http.post(this.apiUrl + '/api/Issues/UpdateContractorDetails', body, options);
    }

    /*****************************************************************
    UploadIssueImage()
    param:  image to be uploaded
    purpose : this method will upload the selected image to server.
    *******************************************************************/
    UploadIssueImage(file): Observable<string> {
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<string>(this.apiUrl + '/api/issues/upload', formData);
    }

    /*****************************************************************
        DeleteUploadedFile()
        param:  image to be deleted
        purpose : this method will delete the selected image to server.
        *******************************************************************/
    DeleteUploadedFile(fileName: string) {
        let body = {}
        //console.log(fileName);
        return this.http.post(this.apiUrl + '/api/Issues/DeleteUploadedFile?fileName=' + fileName, body);
    }
    /************************************************************
    Description: This method will get user info
    **************************************************************/
    getUserInfo() {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        return this.http.get(this.apiUrl + '/api/Users/GetUserInfo', options);
    }
    getwidgetForTenant() {

    }

    getAllPropertyUnits(propertyUid): Observable<any> {
        return this.http.get(this.apiUrl + '/api/Properties/GetAllProperyUnits/' + propertyUid);
    }
    /*************************************************************************
Purpose: Get all user to display list on user dashboard
***************************************************************************/
    getAllUsers(userFilterCriteria) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(userFilterCriteria);
        return this.http.post(this.apiUrl + '/api/Users/GetAll', body, options);
    }

    /*****************************************************************
getAllSupplier()
purpose : this method will get all properties for current user
*******************************************************************/
    getAllSupplier(data) {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        let body = JSON.stringify(data);
        return this.http.post(this.apiUrl + '/api/Vendors/GetAll', body, options);
    }
    getIssuedDashBoardData(data) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(data);
        return this.http.post(this.apiUrl + '/api/Issues/GetIssueDashboardDataForSuperUsers', body, options);
    }


}  
