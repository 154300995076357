import { Component, Input} from '@angular/core';

@Component({
    selector: 'app-file-preview-icon',
    templateUrl: './file-preview-icon.template.html',
    styleUrls: ['./file-preview-icon.style.css']
})
export class FilePreviewIconComponent  {

    @Input() fileType: string = '';
    @Input() file?: any;

    constructor() { }

    ngOnInit() {
    }

}
