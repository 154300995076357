import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from '@app/core/services/shared.service';

@Injectable({
    providedIn: 'root'
})
export class AccountAPI {

    private apiUrl = '';

    constructor(public sharedService: SharedService, private http: HttpClient) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }


    userLogin(object) {
        const options = {
            headers: new HttpHeaders({
                'content-type': 'application/json'
            })
        }
        let body = JSON.stringify(object);
        return this.http.post(this.apiUrl + '/api/Users/Login', body, options);
    }


    /*************************************************************
     Parameter: Invitatoin code
     Description: This method will get user details by invitation code
    **************************************************************/

    getUserDetailsByInvitationCode(invitationcode) {
        return this.http.get(this.apiUrl + '/api/Users/GetInvitationDetailsByInvitationCode?invitationCode=' + invitationcode);
    }

    /*************************************************************
     Parametre: User registration details
     Description: This method will provide the user registration status
    **************************************************************/

    completeUserRegistration(registrationDetails: any) {
        const options = {
            headers: new HttpHeaders({
                'content-type': 'application/json'
            })
        }
        let body = JSON.stringify(registrationDetails);
        return this.http.post(this.apiUrl + '/api/Users/CompleteUserRegistration', body, options);
    }

    /*************************************************************
     Parametre: User email id
     Description: This method will check email exist or not
    **************************************************************/
    isEmailExist(email: string) {
        return this.http.get(this.apiUrl + '/api/Users/IsEmailExists?emailId=' + email)
    }



    /*************************************************************
       Parametre: forgetPasswordRequest
       Description: This method will reuest for reset the password
      **************************************************************/

    forgetPasswordRequest(request) {
        const options = {
            headers: new HttpHeaders({
                'content-type': 'application/json'
            })
        }
        let body = JSON.stringify(request);
        return this.http.post(this.apiUrl + '/api/Users/ForgotPasswordRequest', body, options);
    }

    resetPassword(request) {
        const options = {
            headers: new HttpHeaders({
                'content-type': 'application/json'
            })
        }
        let body = JSON.stringify(request);
        return this.http.post(this.apiUrl + '/api/Users/ResetForgotPassword', body, options);
    }

    changePassPassword(request) {
        const options = {
            headers: new HttpHeaders({
                'content-type': 'application/json'
            })
        }
        let body = JSON.stringify(request);
        return this.http.post(this.apiUrl + '/api/Users/ChangePassword', body, options);
    }

    /************************************************************
    Description: This method will get user info
   **************************************************************/
    getUserInfo() {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        return this.http.get(this.apiUrl + '/api/Users/GetUserInfo', options);
    }

    getUserData() {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        return this.http.get(this.apiUrl + '/api/Users/GetUserInfoNew', options);
    }
}