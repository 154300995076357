import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StorageService } from '@app/core/services/storage.service';
import { SharedService } from '@app/core/services/shared.service';
import { LayoutService } from '../layout.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  constructor(private storageService: StorageService, public router: Router, public sharedService: SharedService, public layoutService: LayoutService) { }
  showSearch = false;

  ngOnInit() {
    this.valueChange.emit(this.showSearch = true);
  }

  onActivate(event) {
    let token = this.storageService.getValue('access_token');
    let loginStatus = this.sharedService.isUserLoggedIn;
    this.checkPermitPages(this.router.url);
    if (token === null || loginStatus === false) {
      document.getElementById('main-app').classList.remove('main-margin');
    } else {
      document.getElementById('main-app').classList.add('main-margin');
    }
  }



  /*****************************************************************
checkPermitPages()
purpose :  Method to trigger custom event to check if user is on permit module or not
*******************************************************************/
  public checkPermitPages(url) {
    if (url.includes('permit-request')) {
      this.sharedService.isPermit = true
    }
    else {
      this.sharedService.isPermit = false
    }
  }

}
