import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '@app/core/services/shared.service';
import { StorageService } from '@app/core/services/storage.service';
import { AccountAPI } from '@app/shared/apis/account.api';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  public redirectURI = '';
  public authToken = '';
  public eventType = '';
  public propertyUId = '';
  public userInfo: any;

  constructor(private route: ActivatedRoute, private storageService: StorageService, private accountAPI: AccountAPI,public sharedService: SharedService) {
    this.route.queryParams.subscribe(params => {
      this.redirectURI = params["redirectURl"] || "";
      this.authToken = params["authToken"] || "";
      this.propertyUId = params["propertyUId"] || "";
      this.eventType = params["eventType"] || "";
    });
  }

  ngOnInit() {
    if (this.propertyUId !== '' && this.eventType !== '') {
      let visitorObject = {
        vSelectedPropertyUId: this.propertyUId,
        vEventType: parseInt(this.eventType)
      }
      this.storageService.eventDataFromVisitors.push(visitorObject);
      localStorage.setItem("visitor_value", JSON.stringify(visitorObject));
    }

    if (this.authToken !== '') {
      localStorage.setItem('access_token', this.authToken);
      //console.log('Check this' , this.redirectURI)
      if (this.redirectURI !== "") {
        this.getUser();
      }
    }
  }

  getUser() {
    // new one
    let loggedInUserInfo = JSON.parse(localStorage.getItem("user_info"));
    if (loggedInUserInfo !== undefined && loggedInUserInfo !== null && loggedInUserInfo !== "") {
      this.getUserInformation();
    } else {
      this.accountAPI.getUserData().subscribe(
        response => {
          let loggedInUserInfo = response;
          localStorage.setItem('user_info', JSON.stringify(loggedInUserInfo));
          this.getUserInformation();
        }, error => {
          
        });
    }
  }

  getUserInformation() {
    //old one
    this.sharedService.isAdminUser = false;
    let loggedInUser = JSON.parse(localStorage.getItem("user_data"));
    if (loggedInUser !== undefined && loggedInUser !== null && loggedInUser !== "" ) {
      this.userInfo = loggedInUser;
      window.location.href = this.redirectURI;
    } else {
      this.accountAPI.getUserInfo().subscribe(
        response => {
          this.userInfo = response;
          localStorage.setItem('user_data', JSON.stringify(this.userInfo));
          localStorage.setItem('org-type', this.userInfo.orgType);
          if (this.userInfo.orgType === 'SuperAdmin' || this.userInfo.orgType === 'ClientAdmin') {
            this.sharedService.isAdminUser = true;
            }
          window.location.href = this.redirectURI;
        }, error => {
          
        });
    }
  }

}


