import { Component, OnInit } from '@angular/core';
import { UserNotificationSettingAPI } from '@app/shared/apis/user-notification.api';
import { LayoutService } from '../layout.service';
import { UsersAPI } from '@app/shared/apis/users.api';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  userData: any;

  constructor(private userNotification: UserNotificationSettingAPI, public layoutService: LayoutService, private usersAPI: UsersAPI) { }


  ngOnInit() {
    this.getUserInfo();
  }

  getUserInfo() {
    // old user data
    let loggedInUser = JSON.parse(localStorage.getItem("user_data"));
    if (loggedInUser !== undefined && loggedInUser !== null && loggedInUser !== "") {
      this.userData = loggedInUser
      this.layoutService.currentUser = this.userData;
      this.getUserInformation();
    } else {
      this.usersAPI.getUserInfo().subscribe(
        response => {
          this.userData = response
          //console.log('features : ' + this.userData)
          this.layoutService.currentUser = this.userData;
          this.getUserInformation();
        }
      )
    }
  }

  getUserInformation() {
    // new user_info
    let loggedInUserInfo = JSON.parse(localStorage.getItem("user_info"));
    if (loggedInUserInfo !== undefined && loggedInUserInfo !== null && loggedInUserInfo !== "") {
      this.layoutService.userData = loggedInUserInfo
    } else {
      this.usersAPI.getUserInformation().subscribe(
        response => {
          //console.log('user info', response);
          this.layoutService.userData = response
        }
      )
    }


  }

  // returns the relevant URL based on the notification type
  calculateNotificationUrl(notificationType: string, id: string) {
    switch (notificationType) {
      case 'visitor-arrived':
        return ('/visitors/visitor-management/' + id);
      case 'issue-reported':
        return ('/issue/detail/' + id);
    }
  }
}
