import { Injectable } from "@angular/core";
import { SharedService } from '@app/core/services/shared.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserNotificationSettingAPI {
    private aipUrl = '';
    constructor(public sharedService: SharedService, private http: HttpClient) {
        this.aipUrl = this.sharedService.getAPIUrl();
    }

    /************************************************************
     Purpose: To get user notification setting
    **********************************************************/
    getUserNotificationSetting(): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.get(this.aipUrl + '/api/Users/GetUserNotificationSetting', options);
    }
    /**************************************************************
     * searchUserNotificationSettin(obj)
     * purpose - to get list of user notificaion list with search and pagination features
     **************************************************************/

    searchUserNotificationSetting(notificationData) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.aipUrl + '/api/Users/SearchUserNotificationSetting', notificationData, options)
    }
    saveUserNotificationSetting(notificationData: any): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.aipUrl + '/api/Users/SaveUserNotificationSetting', notificationData, options);
    }

}