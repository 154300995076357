import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedService } from '@app/core/services/shared.service';
import { Events } from '@app/core/models/events.model';

@Injectable({
    providedIn: 'root'
})
export class VisitorApi {
    private apiUrl = '';

    private authToken = "";
    constructor(public sharedService: SharedService, private http: HttpClient) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }

    /*****************************************************************
        getAllProperties()
        purpose : this method will get all properties for current user
    *******************************************************************/
    getAllProperties(propertyCriteria) {
        const option = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const body = propertyCriteria;
        //console.log('api criteriaValue' + propertyCriteria);
        return this.http.post(this.apiUrl + '/api/Properties/GetAll', body, option);
    }

    getAllPropertiesForList(propertyCriteria) {
        const option = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const body = propertyCriteria;
        return this.http.post(this.apiUrl + '/api/Properties/GetAllPropertiesForList', body, option);
    }

    /*****************************************************************
     GetAllEvents()
     purpose : this method will get all events from API
     *******************************************************************/
    getAllVisitor(VisitorObj) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.apiUrl + '/api/Events/GetAll', JSON.stringify(VisitorObj), options);
    }

    getPropertyByUid(propertyUid): Observable<any> {
        return this.http.get(this.apiUrl + '/api/Properties/Get/' + propertyUid)
    }

    /*****************************************************************
    GetAllEvents()
    purpose : this method will get all events from API
    *******************************************************************/
    // getAllVisitorPlus(VisitorObj) {
    //    
    //     let temp = new Date(VisitorObj.tillDate)
    //     let selectFDate = temp.getDate() + 1;
    //     let selectMonth = temp.getMonth();
    //     let selectYear = temp.getFullYear();
    //     let incrementDate = new Date(selectYear, selectMonth, selectFDate);

    //     let selectEndTime = "5:29";
    //     let seletcEndTimeKeys = selectEndTime.split(':');
    //     let seletcEndHour = Number(seletcEndTimeKeys[0]);
    //     let selectEndMin = Number(seletcEndTimeKeys[1]);
    //     // selectNextTillDay.setDate(selectNextTillDay.getDate() + 1);
    //     incrementDate.setHours(seletcEndHour, selectEndMin);
    //     VisitorObj.tillDate = incrementDate.toISOString();

    //     const options = {
    //         headers: new HttpHeaders({
    //             'Content-Type': 'application/json'
    //         })
    //     };
    //     return this.http.post(this.apiUrl + '/api/Events/GetAll', JSON.stringify(VisitorObj), options);
    // }
    /*****************************************************************
    getEvent()
    param: event id
    purpose : this method will get the event by id
  *******************************************************************/
    getEvent(id: string): Observable<Events> {
        return this.http.get<Events>(this.apiUrl + '/api/Events/Get/' + id);
    }


    checkIn(object) {

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.put(this.apiUrl + '/api/Events/CheckInVisitors/' + object.uId, JSON.stringify(object), options);
    }
    checkOut(object) {

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.put(this.apiUrl + '/api/Events/CheckOutVisitors/' + object.uId, JSON.stringify(object), options);
    }

    /*****************************************************************
       deleteEvent()
       param:  event to be delete
       purpose : this method will delete the event by id
       *******************************************************************/
    deleteVisitor(id: string, version: any) {
        return this.http.delete(this.apiUrl + '/api/Events/Delete/' + id + '?version=' + version);
    }
}