import { Component, OnInit } from '@angular/core';
import { UserNotificationSettingAPI } from '@app/shared/apis/user-notification.api';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css']
})
export class TopnavComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

  

}
