import { Injectable, EventEmitter } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class GlobalSearchService {

    public globalSearchEventEmitter: EventEmitter<any> = new EventEmitter();
    public showSearchEventEmitter: EventEmitter<any> = new EventEmitter();

    constructor() {

    }
    public globalSearchEventListner() {
        return this.globalSearchEventEmitter;
    }

    public globalShowSearchEventListner() {
        return this.showSearchEventEmitter;
    }
}